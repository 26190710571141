<template>
  <div class="labor-activity">
    <div class="labor-activity__body">
      <InputSearch
        header="Город"
        placeholder="Выберите из списка"
        :results-array="citiesList"
        @form-item="setIdCity"
        v-model="city_name"
        :error="
          v$.formData.$each.$response &&
          v$.formData.$each.$response?.$errors[0].city_id &&
          v$.formData.$each.$response?.$errors[0].city_id.length !== 0
        "
        :disabled="isDisabled"
      />
      <Input
        header="Место трудоустройства (наименование организации/учреждения/самозанятый)"
        placeholder="Введите значение"
        v-model="formData[0].organization_name"
        :error="
          v$.formData.$each.$response &&
          v$.formData.$each.$response?.$errors[0].organization_name &&
          v$.formData.$each.$response?.$errors[0].organization_name.length !== 0
        "
        :disabled="isDisabled"
      />
      <Input
        header="Должность (полное наименование)"
        placeholder="Введите значение"
        v-model="formData[0].organization_post"
        :error="
          v$.formData.$each.$response &&
          v$.formData.$each.$response?.$errors[0].organization_post &&
          v$.formData.$each.$response?.$errors[0].organization_post.length !== 0
        "
        :disabled="isDisabled"
      />
      <Input
        header="Дата последнего места трудоустройства / Дата регистрации самозанятого"
        placeholder="01.10.2000"
        type="date"
        :min="minBirthday"
        :max="maxBirthday"
        v-model="formData[0].organization_date_at"
        :autocomplete="false"
        @mousedown="formData[0].organization_date_at = ''"
        :error="
          (v$.formData.$each.$response &&
            v$.formData.$each.$response?.$errors[0].organization_date_at &&
            v$.formData.$each.$response?.$errors[0].organization_date_at
              .length !== 0) ||
          isBefore1970(formData[0].organization_date_at) ||
          isAfterToday(formData[0].organization_date_at)
        "
        :disabled="isDisabled"
        @blur="
          validateMaxDate(formData[0].organization_date_at) ||
            validateMinDate(formData[0].organization_date_at)
        "
      />
      <div class="labor-activity__btn" v-if="!isDisabled">
        <Button
          @click="saveChanges"
          :loading="isBtnLoading"
          :disabled="isBtnDisabled"
        >
          Сохранить изменения
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import Input from "@/components/Blocks/Input";
import InputSearch from "@/components/Blocks/InputSearch";
import Button from "@/components/Blocks/Button";
import { mapMutations, mapState } from "vuex";
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";
import moment from "moment";
import useVuelidate from "@vuelidate/core";
import { helpers, maxLength, minLength, required } from "@vuelidate/validators";

export default {
  name: "LaborActivity",
  components: { Button, InputSearch, Input },
  setup() {
    return {
      v$: useVuelidate({ $lazy: true }),
    };
  },
  data() {
    return {
      isBtnLoading: false,
      isBtnDisabled: true,
      city_name: "",
      formData: [
        {
          id: null,
          organization_name: "",
          organization_post: "",
          organization_date_at: "",
          city_id: "",
        },
      ],
    };
  },
  props: ["project", "currentProject"],
  watch: {
    formData: {
      handler: function () {
        this.isBtnDisabled = false;
      },
      deep: true,
    },
    laborActivity: {
      handler: async function () {
        await this.formDataUpdate();
      },
      deep: true,
    },
  },
  validations() {
    return {
      formData: {
        $each: helpers.forEach({
          organization_name: {
            required: helpers.withMessage("Обязательно к заполнению", required),
            minLength: helpers.withMessage(
              "Поле должно быть не менее 3 символов",
              minLength(3)
            ),
            maxLength: helpers.withMessage(
              "Поле должно быть не более 100 символов",
              maxLength(100)
            ),
          },
          organization_post: {
            required: helpers.withMessage("Обязательно к заполнению", required),
            minLength: helpers.withMessage(
              "Поле должно быть не менее 3 символов",
              minLength(3)
            ),
            maxLength: helpers.withMessage(
              "Поле должно быть не более 100 символов",
              maxLength(100)
            ),
          },
          organization_date_at: {
            required: helpers.withMessage("Обязательно к заполнению", required),
          },
          city_id: {
            required: helpers.withMessage("Обязательно к заполнению", required),
          },
        }),
      },
    };
  },
  computed: {
    isDisabled() {
      let boolean;
      if (this.project) {
        if (Object.values(this.currentCategoryData).length > 0) {
          if (this.validPeriodForRollback) {
            if (this.currentCategoryData?.status?.key === 0) {
              boolean = false;
            } else boolean = this.currentCategoryData?.status?.key !== 35;
          } else if (this.validPeriodFor35Status) {
            boolean = this.currentCategoryData?.status?.key !== 35;
          } else return true;
        }
      }

      return this.project ? this.isOldApplication || boolean : boolean;
    },
    openRegistrationStage() {
      return this.currentProject?.settings?.stages?.data.find((item) => {
        return item.stage_key === "open_registration";
      });
    },
    checkStatementEmployee() {
      return this.currentProject?.settings?.stages?.data.find((item) => {
        return item?.stage_key === "check_statement_employee";
      });
    },
    validPeriodForRollback() {
      return moment().isBetween(
        moment(this.openRegistrationStage?.start_at),
        moment(this.openRegistrationStage?.end_at),
        "day",
        "[]"
      );
    },
    validPeriodFor35Status() {
      return moment().isBetween(
        moment(this.checkStatementEmployee?.start_at),
        moment(this.checkStatementEmployee?.end_at),
        "day",
        "[]"
      );
    },
    fieldsInfo() {
      return {
        organization_name: "Место трудоустройства",
        organization_post: "Должность (полное наименование)",
        organization_date_at:
          "Дата последнего места трудоустройства / Дата регистрации самозанятого",
        city_id: "Город трудоустройства",
      };
    },
    citiesList() {
      let sortedArr = JSON.parse(
        JSON.stringify(
          Object.values(this.storage.systemInfo?.educational_cities)
        )
      ).filter((city) => city?.region_id === 5246);

      sortedArr.sort((a, b) => {
        let fieldA = a.title.toLowerCase(),
          fieldB = b.title.toLowerCase();

        if (fieldA < fieldB) return -1;

        if (fieldA > fieldB) return 1;

        return 0;
      });

      return sortedArr;
    },
    minBirthday() {
      return moment("01.01.1970").format("YYYY-MM-DD");
    },
    maxBirthday() {
      return moment().format("YYYY-MM-DD");
    },
    ...mapState(["storage", "currentCategoryData", "isOldApplication"]),
    ...mapState("user", ["laborActivity"]),
  },
  methods: {
    async saveChanges() {
      if (this.isBefore1970(this.formData[0].organization_date_at)) {
        this.$store.commit("pushToTray", {
          text: "Дата не может быть раньше 1 января 1970 года",
          type: "error",
        });
        return;
      }
      if (this.isAfterToday(this.formData[0].organization_date_at)) {
        this.$store.commit("pushToTray", {
          text: "Дата не может быть позже текущего дня",
          type: "error",
        });
        return;
      }

      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        this.$store.dispatch("validationFormErrors", {
          errors: this.v$.formData.$each.$response.$errors,
          fieldsInfo: this.fieldsInfo,
        });
        return;
      }
      this.isBtnLoading = true;
      await createRequest(requestConfigs.POSTSaveLaborActivity, {
        jsonPayload: { labor_activity: this.formData },
      })
        .then(async (response) => {
          await this.$store.commit("pushToTray", {
            text: "Данные успешно сохранены",
            type: "success",
          });
          await this.setLaborActivity(
            response.data.map((item) => {
              return {
                id: item.id,
                organization_name: item.organization_name,
                organization_post: item.organization_post,
                organization_date_at: moment(item.organization_date_at).format(
                  "YYYY-MM-DD"
                ),
                info_city: item.info_city,
              };
            })
          );
          await this.$store.commit("user/setDisabledData", [
            "isLaborActivityFilled",
            this.laborActivity.length > 0,
          ]);
          if (this.project)
            await this.$router.push({ name: "HousingStockPersonalData" });
          this.formDataUpdate();
        })
        .catch((error) => {
          this.$store.commit("pushToTray", {
            text: error.errors[0].error_descr,
            type: "error",
          });
        })
        .finally(() => {
          this.isBtnLoading = false;
        });
    },
    setIdCity(item) {
      this.formData[0].city_id = item.id;
    },
    formDataUpdate() {
      this.city_name = this.laborActivity[0]?.info_city?.title_full;
      this.formData = this.laborActivity.map((item) => {
        return {
          id: item.id,
          organization_name: item.organization_name,
          organization_post: item.organization_post,
          organization_date_at: moment(item.organization_date_at).format(
            "YYYY-MM-DD"
          ),
          city_id: item.info_city?.id,
        };
      });
    },
    isBefore1970(value) {
      return moment(value).isBefore(moment("01.01.1970").format("YYYY-MM-DD"));
    },
    isAfterToday(value) {
      return moment(value).isAfter(moment());
    },
    validateMinDate(value) {
      if (this.isAfterToday(value)) {
        this.$store.commit("pushToTray", {
          text: "Дата не может быть позже текущего дня",
          type: "error",
        });
        this.isDateInvalid = true;
      } else this.isDateInvalid = false;
    },
    validateMaxDate(value) {
      if (this.isBefore1970(value)) {
        this.$store.commit("pushToTray", {
          text: "Дата не может быть раньше 1 января 1970 года",
          type: "error",
        });
        this.isDateInvalid = true;
      } else this.isDateInvalid = false;
    },
    ...mapMutations("user", ["setLaborActivity"]),
  },
  async mounted() {
    if (this.laborActivity.length > 0) await this.formDataUpdate();
    if (!this.project) this.isBtnDisabled = true;
  },
};
</script>

<style lang="scss">
.labor-activity {
  &__info {
    font-weight: 500;
  }
  &__body {
    max-width: 730px;
  }
  &__btn {
    margin-top: 20px;
  }
}
</style>
